export const utilsMixin = {
    methods: {
        showNotification(notifTitle, notifOptions){
            if(Notification.permission === "granted"){
                navigator.serviceWorker.getRegistration()
                    .then(reg => {
                        reg.showNotification(notifTitle, notifOptions)
                    })
            }
        }
    },
}