const mqtt = require("mqtt");

const config = {
    host: "mqtt.rd.atosworldline.com",
    port: 8003,
    username: "rd",
    password: "@t0s@t0s",
    rejectUnauthorized: false,
    protocol: 'wss'
};

const topics = {
    DEFAULT_TOPIC: "rd/chatbot/connectedKitchenBot",
    VALINA_PAYMENT: "/payment/terminal",
    YOMANI_PAYMENT: "/scanYomani/launchReservation",
    YUMI_PAYMENT: "",
    SEPTAGONE_PAYMENT: "/{terminalId}/payment/terminal",
    PAYMENT_WAITING: "/payment/waiting/{complexId}",
    PAYMENT_TICKET: "/payment/ticket/{complexId}",
    SMART_SHELVES: "/smartShelves/basket/{id}/#",
    LOYALTY_SENT: "/{terminalId}/loyalty/sent",
    SEND_SUBSTATE: "/IEC/orders/refresh",
    LOCKER: "rd/locker/{lockerLocation}/{lockerID}/#"
}
    


String.prototype.format = function(params) {
    let string = this;
    let keys = Object.keys(params)
    for (let i in keys) {
        string = string.replace("{" + keys[i] + "}", params[keys[i]])
    }
    return string
}

let mqttReady = false;
let client;

function initMqttClient(callback = null){

    console.log("Init MQtt")

    if(!client || !client.connected){
        client = mqtt.connect(config);
        client.on('connect', () => {
            console.log("connected to mqtt server")
            mqttReady = true;
            if(callback != null){
                callback();
            }
        });

        client.stream.on('error', (err) => {
            if(err){
                console.error("Error while connection to MQTT broker");
                client.end();
            }
        });
        client.on("close", () => {
            client.end();
        })
    }else{
        console.log("MQTT Already connected")
        callback()
    }

}
    


function subscribeMqtt(topic, callback){
        if(client && client.connected && mqttReady){
            client.subscribe(topic, err => {
                if(!err){
                    console.log("Subscribed to topic " + topic);
                    callback();
                }else{
                    console.error("Error while subscribing to topic " + err);
                }
            });
        }else{
            initMqttClient(subscribeMqtt(topic, callback));
        }
}

function listenMqttMessage(topicToListen, callback){
    client.on('message', (topic, message)=> {
        if(topicToListen == topic){
            console.log("Message received on topic " + topic);
            callback(message);
        }
    });
}

function unsubscribe(topic, callback){
    client.unsubscribe(topic, callback);
}

function publishMqtt(topic, message, callback){
    if(client && client.connected && mqttReady){
        client.publish(topic, message, () => {
            console.log("Message published in topic " + topic);
            callback();
        });
    }else{
        initMqttClient(publishMqtt(topic, message, callback));
    }

}

function getClient(){
    return client;
}

function isMqttReady(){
    return mqttReady;
}

function endClient(){
    client.end(true);
}

const MQTT = {
    topics,
    subscribeMqtt,
    publishMqtt,
    listenMqttMessage,
    getClient,
    initMqttClient,
    endClient,
    unsubscribe,
    isMqttReady
}

export default MQTT;