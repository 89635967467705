export const pageInfoMixin = {
    created(){
        this.updatePageInfo()
    },
    methods:{
        updatePageInfo(){
            this.$emit("pageCreated", this.page)
        }
    }
    ,
    watch:{
        page(oldVal, newVal){
            console.log(oldVal, newVal)
        }
    }
}