import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "Food",
            component: () => import('@/components/Food/Food.vue'),
            children: [
                {
                    path: '',
                    name: 'Index',
                    component: () => import('@/components/Food/Home.vue'),
                    meta:{
                        title: "Bienvenue",
                        description: "Avec Click&Collect, vous pouvez effectuer vos achats rapidement depuis n'importe où, sans contact, jusqu’au retrait.",
                        shopName:"Magasin Worldline",
                        showBackBtn: false,
                        showFilterBtn: false
                    }
                },
                {
                    path: '/category/:category',
                    name: 'CategoryProducts',
                    component: () => import('@/components/Food/CategoryProducts.vue'),
                    meta:{
                        title: "Category",
                        description: null,
                        showBackBtn: true,
                        showFilterBtn: true
                    } 
                },
                {
                    path: '/scan',
                    name: 'Scan',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: '/basket',
                    name: "Basket",
                    component: () => import('@/components/Food/Basket.vue'),
                    meta:{
                        title: "Basket",
                        description: null,
                        showBackBtn: true,
                        showFilterBtn: true
                    } 
                },
                {
                    path: '/paymentmethods',
                    name: "PaymentMethods",
                    component: () => import('@/components/Food/PaymentMethods.vue'),
                    meta:{
                        title: "Paiement",
                        description: null,
                        showBackBtn: true,
                        showFilterBtn: false
                    }   
                },
                {
                    path: '/paymentdone',
                    name: "PaymentDone",
                    props: (route) => ({
                        paymentSuccess: route.query.paymentSuccess
                    }),
                    component: () => import('@/components/Food/Ticket.vue'),
                    meta:{
                        title: "Order confirmation",
                        description: null,
                        showBackBtn: false,
                        showFilterBtn: false
                    }  
                },
                {
                    path: '/ticket',
                    name: "PaymentTicket",
                    component: () => import('@/components/Food/Ticket.vue') 
                },
                {
                    path: '/account',
                    component: () => import('@/components/Food/Account.vue') 
                },
                {
                    path: '/checkin',
                    name: "CheckIn",
                    component: () => import('@/components/Food/CheckIn.vue') 
                },
                {
                    path: '/schedule',
                    component: () => import('@/components/Food/StoreSchedule.vue') 
                },
                {
                    path: '/sips',
                    component: () => import('@/components/Food/PaymentMethods/Sips.vue') 
                },
                {
                    path: '/wlop',
                    component: () => import('@/components/Food/PaymentMethods/Wlop.vue') 
                },
                {
                    name: 'FoodIndex',
                    path: '/w3c',
                    component: () => import('@/components/Food/PaymentMethods/W3CPayment.vue') 
                },
                {
                    name: 'Orders',
                    path: '/orders',
                    component: () => import('@/components/Food/MyOrders.vue') 
                }
            ]
        },
        {
            path: "/backoffice/:demoId/:locale/",
            name: "Backoffice",
            component: () => import('@/components/Backoffice/Backoffice.vue'),
            children: [
                {
                    name: "KeywordsList",
                    path: 'keywords',
                    component: () => import('@/components/Backoffice/KeywordsList.vue') 
                },
                {
                    name: "Basket",
                    path: 'basket/keyword/:keyword',
                    component: () => import('@/components/Backoffice/Basket.vue') 
                }
            ]
        }
    ]
})
