<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
    export default {
      name: 'app',
      created(){
        document.dispatchEvent(new Event('x-app-rendered'))
      }
    }
</script>
